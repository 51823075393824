<template>
  <footer class="m-10">
    <p>Copyright &copy; {{ new Date().getFullYear() }} Emily K All Rights Reserved</p>
  </footer>
</template>

<script>
export default {
    name: "Footer"
}
</script>

<style scoped>

</style>